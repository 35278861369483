import { cva } from 'class-variance-authority';
export const buttonStyles = cva([
    'items-center',
    'select-none',
    'box-border',
    '::before:box-border',
    '::after:box-border',
    'flex',
    'flex-row',
    'flex-shrink-0',
    'min-w-fit',
    'justify-center',
    'focus:outline-none',
    'focus-visible:ring-button-focus',
    'transition-colors',
    'duration-100',
    'ease-in-out',
    'whitespace-nowrap',
    'disabled:cursor-not-allowed',
    'data-[state=loading]:cursor-wait',
], {
    variants: {
        variant: {
            filled: '',
            muted: '',
            ghost: 'bg-transparent',
            dashed: [
                'border-dashed border-button-dashed border-button hover:border-button-dashed-hover active:border-button-dashed-press disabled:border-button-dashed-disabled',
                'bg-button-dashed hover:bg-button-dashed-hover',
                'active:bg-button-dashed-press disabled:bg-button-dashed-disabled disabled:text-button-onneutral-muted-disabled',
                'data-[state=loading]:bg-button-dashed-loading',
            ],
        },
        mutedText: {
            true: '!text-button-onneutral-muted-tertiary hover:!text-button-onneutral-muted-primary',
        },
        blurEffect: {
            true: 'backdrop-blur',
        },
        color: {
            default: '',
            cta: '',
            destructive: [
                'bg-button-destructive-muted border border-button-destructive text-button-ondestructive-muted-primary',
                'hover:bg-button-destructive-muted-hover hover:border-button-destructive-hover',
                'active:bg-button-destructive-muted-press active:border-button-destructive-press',
                'disabled:text-button-ondestructive-muted-disabled disabled:bg-button-destructive-muted-disabled disabled:border-transparent',
            ],
        },
        onlyIcon: {
            true: '',
        },
        size: {
            xs: 'h-button-xs px-button-xs rounded-button-xs button-xs gap-button-xs',
            sm: 'h-button-sm px-button-sm rounded-button-sm button-sm gap-button-sm',
            md: 'h-button-md px-button-md rounded-button-md button-md gap-button-md',
            lg: 'h-button-lg px-button-lg rounded-button-lg button-lg gap-button-lg',
            xl: 'h-button-xl px-button-xl rounded-button-xl button-xl gap-button-xl',
        },
    },
    compoundVariants: [
        /**
         * Default
         */
        {
            variant: 'filled',
            color: 'default',
            className: [
                'text-button-onneutral-primary',
                'bg-button-neutral hover:bg-button-neutral-hover active:bg-button-neutral-press',
                'border border-button-neutral hover:border-button-neutral-hover active:border-button-neutral-press',
                'disabled:bg-button-neutral-disabled disabled:text-button-onneutral-disabled',
                'data-[state=loading]:bg-button-neutral-loading data-[state=loading]:icon-button-onneutral-muted-primary',
            ],
        },
        {
            variant: 'muted',
            color: 'default',
            className: [
                'text-button-onneutral-muted-primary',
                'bg-button-neutral-muted hover:bg-button-neutral-muted-hover active:bg-button-neutral-muted-press',
                'border border-button-neutral-muted hover:border-button-neutral-muted-hover active:border-button-neutral-muted-press',
                'disabled:bg-button-neutral-muted-disabled disabled:text-button-onneutral-muted-disabled disabled:border-button-neutral-muted-disabled',
                'data-[state=loading]:bg-button-neutral-muted-loading data-[state=loading]:border-button-neutral-muted-loading',
            ],
        },
        {
            variant: 'ghost',
            color: 'default',
            className: [
                'text-button-onneutral-muted-primary',
                'hover:bg-button-neutral-muted-hover active:bg-button-neutral-muted-press',
                'disabled:text-button-onneutral-muted-disabled disabled:bg-transparent',
                'data-[state=loading]:bg-transparent',
            ],
        },
        /**
         * CTA
         */
        {
            variant: 'filled',
            color: 'cta',
            className: [
                'text-button-onbrand-primary bg-button-brand hover:bg-button-brand-hover active:bg-button-brand-press',
                'disabled:bg-button-brand-disabled disabled:text-button-onbrand-disabled',
                'data-[state=loading]:bg-button-brand-loading ',
            ],
        },
        {
            variant: 'muted',
            color: 'cta',
            className: [
                'text-button-onbrand-muted-primary bg-button-brand-muted hover:bg-button-brand-muted-hover active:bg-button-brand-muted-press',
                'disabled:bg-button-brand-muted-disabled disabled:text-button-onbrand-muted-disabled',
                'data-[state=loading]:bg-button-brand-muted-loading data-[state=loading]:icon-button-onneutral-muted-primary',
            ],
        },
        {
            variant: 'ghost',
            color: 'cta',
            className: [
                'text-button-onbrand-muted-primary bg-transparent hover:bg-button-brand-muted-hover active:bg-button-brand-muted-press',
                'disabled:text-button-onbrand-muted-disabled disabled:bg-transparent',
                'data-[state=loading]:bg-transparent',
            ],
        },
        /**
         * Only Icon
         */
        {
            onlyIcon: true,
            size: 'xs',
            className: 'w-button-xs !px-0 component-xs',
        },
        {
            onlyIcon: true,
            size: 'sm',
            className: 'w-button-sm !px-0 component-sm',
        },
        {
            onlyIcon: true,
            size: 'md',
            className: 'w-button-md !px-0 component-md',
        },
        {
            onlyIcon: true,
            size: 'lg',
            className: 'w-button-lg !px-0 component-lg',
        },
        {
            onlyIcon: true,
            size: 'xl',
            className: 'w-button-xl !px-0 component-xl',
        },
    ],
    defaultVariants: {
        variant: 'filled',
        color: 'default',
        size: 'md',
    },
});
export const iconStyles = cva('shrink-0', {
    variants: {
        size: {
            xs: 'w-button-icon-xs h-button-icon-xs',
            sm: 'w-button-icon-sm h-button-icon-sm',
            md: 'w-button-icon-md h-button-icon-md',
            lg: 'w-button-icon-lg h-button-icon-lg',
            xl: 'w-button-icon-xl h-button-icon-xl',
        },
        onlyIcon: {
            true: '',
        },
    },
    compoundVariants: [
        {
            onlyIcon: true,
            size: 'xs',
            className: 'w-button-icon-only-xs h-button-icon-only-xs',
        },
        {
            onlyIcon: true,
            size: 'sm',
            className: 'w-button-icon-only-sm h-button-icon-only-sm',
        },
        {
            onlyIcon: true,
            size: 'md',
            className: 'w-button-icon-only-md h-button-icon-only-md',
        },
        {
            onlyIcon: true,
            size: 'lg',
            className: 'w-button-icon-only-lg h-button-icon-only-lg',
        },
        {
            onlyIcon: true,
            size: 'xl',
            className: 'w-button-icon-only-xl h-button-icon-only-xl',
        },
    ],
    defaultVariants: {
        size: 'md',
    },
});
