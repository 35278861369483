import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { cloneElement, forwardRef } from 'react';
import { Loader2Filled } from '@zealy/icons';
import { cn } from '../../utils/cn';
import { buttonStyles, iconStyles } from './Button.styles';
export const Button = forwardRef(({ as: Component = 'button', type, children, leftIcon, rightIcon, isDisabled, loading, loadingText, size, color, variant, onlyIcon, className, mutedText, ...props }, ref) => {
    const iconClassName = iconStyles({ size, onlyIcon });
    return (_jsx(Component, { className: cn(buttonStyles({
            size,
            color,
            variant,
            onlyIcon,
            mutedText,
        }), className), "data-state": loading ? 'loading' : isDisabled ? 'disabled' : undefined, type: type, disabled: isDisabled, ref: ref, ...props, children: loading ? (_jsxs(_Fragment, { children: [_jsx(Loader2Filled, { className: cn(iconClassName, 'animate-spin') }), loadingText] })) : (_jsxs(_Fragment, { children: [leftIcon &&
                    cloneElement(leftIcon, {
                        className: cn(leftIcon.props.className, iconClassName),
                    }), onlyIcon ? _jsx("span", { className: "sr-only", children: children }) : children, !onlyIcon &&
                    rightIcon &&
                    cloneElement(rightIcon, {
                        className: cn(rightIcon.props.className, iconClassName),
                    })] })) }));
});
